import { inject, Injectable } from '@angular/core';
import { LanguageConfigService } from './language-config.service';

const FORMATS = {
    _date: 'Y-m-d',
    _date_jq: 'yy-mm-dd',
    _date_js: 'YYYY-MM-DD',
    _datetime: 'Y-m-d H:i',
    _datetime_jq: 'yy-mm-dd HH:mm',
    _datetime_js: 'YYYY-MM-DD HH:mm',
    _short_date: 'YY-MM-DD',
    _short_datetime_js: 'YY-MM-DD HH:mm',
    _time: 'H:i',
    _time_js: 'HH:mm',
};

@Injectable({ providedIn: 'root' })
export class DateTimeFormatService {
    LangConfig = inject(LanguageConfigService);

    get(key: string) {
        const currLang = this.LangConfig.currentLanguage;
        let format;

        // Turkish overrides
        if (currLang === 'tr') {

            if (key === '_date_js') {
                format = 'DD-MM-YYYY';

            } else if (key === '_datetime_js') {
                format = 'DD-MM-YYYY HH:mm';

            } else if (key === '_short_date') {
                format = 'DD-MM-YY';

            } else if (key === '_short_datetime_js') {
                format = 'DD-MM-YY HH:mm';

            } else {
                format = FORMATS[key];

            }

        } else {
            format = FORMATS[key] || key;

        }

        return format;
    };
}
