import { menuAgent } from './agent';
import { menuCustomer } from './customer';
import { menuEmployee } from './employee';
import { menuBookkeepingUser } from './bookkeepingUser';

export const menus = {
    agent: menuAgent,
    customer: menuCustomer,
    employee: menuEmployee,
    bookkeepingUser: menuBookkeepingUser
};
