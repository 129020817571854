import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UI_HIDE_WARNINGS, UiPreferencesService } from './ui-preferences.service';
import { removeHtmlTags } from '../utils';
import { PromanTranslationService } from '@proman/services/proman-translation.service';

/**
 * Toast Service
 * available `pop` methods: success|error|warning|info|show
 */

@Injectable()
export class ToastService {

    constructor(
        private PromanTranslate: PromanTranslationService,
        private Toast: ToastrService,
        private UiPrefs: UiPreferencesService,
    ) {}

    pop(method: 'success'|'error'|'warning'|'warn'|'info'|'show', text: string, translateValues?: any, options: any = {}) {
        if (this.UiPrefs.get(UI_HIDE_WARNINGS) ?? false) return;

        const type = this.getType(method);

        if (type === 'silent') return;
        if (type === 'error') Object.assign(options, { closeButton: true, timeOut: 15000, extendedTimeOut: 5000 });

        if (type === 'link') {
            Object.assign(options, { closeButton: true, timeOut: 15000, extendedTimeOut: 5000, enableHtml: true});
            text = '<a style="color: white" href="' + translateValues.link + '">' + text + '</a>';
            this.Toast.info(text);
            return;
        }

        const values = typeof translateValues === 'object' ? Object.values(translateValues) : translateValues;
        const message = this.PromanTranslate.translate(text, translateValues);
        this.Toast[type](options.enableHtml ? message : removeHtmlTags(message), values, options);
    }

    getType = (method: string) => {
        switch (method) {

            case 'E_USER_WARNING':
            case 'warning':
            case 'warn':
                return 'warning';

            case 'E_USER_ERROR':
            case 'error':
                return 'error';

            case 'E_USER_INFO':
            case 'information':
            case 'info':
                return 'info';

            case 'E_USER_DEBUGGING':
            case 'E_USER_SILENT':
            case 'silent':
                return 'silent';

            case 'E_USER_SUCCESS':
            case 'success':
                return 'success';

            case 'E_USER_LINK':
                return 'link';

            default:
                return (typeof this.Toast[method] === 'function' ? method : 'error');
        }
    }

}
