import { inject, Injectable } from '@angular/core';
import { CONFIG } from '../config';
import { PreferencesService } from './preferences.service';
import { ACL } from './acl.service';
import { RequestService } from './request.service';
import { COOKIE_AUTHORIZATION, CookiesService } from '@proman/services/cookies.service';
import { CurrUser, LoginProject, LoginRequestType } from '@proman/interfaces/object-interfaces';
import { setCurrUser, userLogout } from '@proman/store/curr-user';
import { Store } from '@ngrx/store';
import { ToastService } from '@proman/services/toast.service';
import { WebsocketService } from '@proman/services/websocket.service';
import { Router } from '@angular/router';
import { SystemOptions } from '@proman/interfaces/entity-interfaces';
import { setSystemOptions } from '@proman/store/system-options';
import { LoggerService } from '@proman/services/logger.service';


declare type LoginResponse = CurrUser & Record<'systemOptions', SystemOptions>;

@Injectable({ providedIn: 'root' })
export class AuthService {
    private token: string;
    private sessionToken: string;
    userData: CurrUser;
    LOGGER = inject(LoggerService);

    constructor(
        private ACL: ACL,
        private Request: RequestService,
        private Prefs: PreferencesService,
        private store: Store,
        private Cookies: CookiesService,
        private Toast: ToastService,
        private Websocket: WebsocketService,
        private Router: Router,
    ) {
        this.setToken(this.Cookies.get(COOKIE_AUTHORIZATION));
    }

    init() {

    }

    setToken(token: string|null) {
        this.token = token;
    }

    getToken() {
        return this.token;
    }

    setSessionToken(token: string|null) {
        this.sessionToken = token;
    }

    getSessionToken() {
        return this.sessionToken;
    }

    checkLogin = (params: LoginRequestType): Promise<LoginResponse|void> => {
        const logKey = 'LoginRequest';
        this.LOGGER.track(logKey);
        return this.Request
            .post(`${CONFIG.api}login`, params)
            .then((response:LoginResponse) => {
                this.LOGGER.trackComplete(logKey);

                this.userData = response;

                this.setToken(response.token);
                this.Cookies.set(COOKIE_AUTHORIZATION, this.token);

                return response;
            })
            .catch((err) => this.Toast.pop('error', err.error.message));
    };

    handleTokenChange = (token: string, username: string) => {
        let persistedTokens = this.Prefs.tokens() || {};

        this.setToken(token);

        // add current token to token map
        persistedTokens[username] = token;

        // flush token map to storage
        this.Prefs.tokens(persistedTokens);

        // mark current token as last used
        this.Cookies.set(COOKIE_AUTHORIZATION, token);
    };

    login = (credentials: LoginRequestType, project: LoginProject = LoginProject.FRONTEND, isUserPassCheck?: boolean, twoFactorAuth: boolean = true): Promise<LoginResponse |void> => {
        return this
            .checkLogin({ ...credentials, project })
            .then((response) => {
                if (!response) return;
                if (response.mustChangePassword || (twoFactorAuth && response.twoFactorLoginEnabled)) {
                    return response;
                }

                this.handleTokenChange(response.token, response.username);
                this.store.dispatch(setCurrUser({ payload: response }));
                if (response.systemOptions) this.store.dispatch(setSystemOptions({ payload: response.systemOptions }));

                return response;
            });
    };

    switchToken = (token: string, username: string) => {
        this.handleTokenChange(token, username);
    };

    clearToken = () => {
        this.setToken(null);
    };

    logout = (skipRedirect?: boolean): Promise<void> => {
        return new Promise((resolve) => {
            let tokens = this.Prefs.tokens();

            for (let username in tokens) {

                if (tokens[username] === this.getToken()) {
                    delete tokens[username];
                    break;
                }

            }

            this.Request.post(CONFIG.api + 'user/logout')
                .then(() => {
                    this.Websocket.endSession();
                    if (!skipRedirect) this.Router.navigate(['login']);
                    this.Prefs.tokens(tokens);
                    this.clearToken();
                    this.Cookies.remove(COOKIE_AUTHORIZATION);
                    this.store.dispatch(userLogout());
                    resolve();
                });
        })
    };

}
