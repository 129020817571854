export const menuBookkeepingUser = [
  {
    type: 'group',
    name: 'sales',
    icon: 'briefcase',
    tabs: [
      {
        name: 'invoices',
        state: 'Invoices',
      },
      {
        name: 'customers',
        state: 'Customers',
      },
      {
        name: 'carriers',
        state: 'Carriers',
      },
    ],
  },
  {
    type: 'group',
    name: 'products',
    icon: 'shelves',
    tabs: [
      {
        name: 'products',
        state: 'Products',
      },
      {
        name: 'warehouses',
        state: 'Warehouses',
      },
    ]
  },
  {
    name: 'projects',
    state: 'SaleOpportunities',
    icon: 'folder-open',
  },
  {
    type: 'group',
    name: 'purchases',
    icon: 'coins',
    tabs: [
      {
        name: 'purchases',
        state: 'Purchase',
      },
      {
        name: 'suppliers',
        state: 'Suppliers',
      },
      {
        name: 'carriers',
        state: 'Carriers',
      },
    ],
  },
  {
    type: 'group',
    name: 'staff',
    icon: 'users',
    tabs: [
      {
        name: 'employees',
        state: 'Employees',
        acl: 'employee.display',
      },
      {
        name: 'tabel',
        state: 'Tabel',
      },
      {
        name: 'documents',
        state: 'EmployeesDocuments',
        acl: 'employee.display',
      },
    ]
  },
  {
    type: 'group',
    name: 'accounting',
    icon: 'calculator',
    tabs: [
      {
        name: 'taxes',
        state: 'Taxes',
      },
      {
        name: 'assets',
        state: 'AccountingAssets',
      },
      {
        name: 'balance',
        state: 'Balance',
      },
    ],
  },
  {
    name: 'settings',
    state: 'AccountProps',
    icon: 'cogs',
  },
]
