import { Injectable } from '@angular/core';
import { resourcesConfig } from '../resources';
import { DateTimeFormatService } from './date-time-format.service';
import {
    durationFromSeconds,
    isArray, isLocalhost,
    isValidDateString, lowercase,
    numberDecimalComma,
    uppercase,
    utcTime
} from '@proman/utils';
import moment from 'moment';
import { Moment } from 'moment';
import { ACL } from './acl.service';
import { Material, MaterialQuant, SystemOptions } from '../interfaces/entity-interfaces';
import { CurrenciesService } from './currencies.service';
import { Store } from '@ngrx/store';
import { getSystemOptions } from '@proman/store/system-options';
import { getCurrUser } from '@proman/store/curr-user';
import { CurrUser } from '../interfaces/object-interfaces';
import { Money } from '../interfaces/common.interface';
import { AppInitService } from '@proman/services/app-init.service';
import { PreferencesService } from '@proman/services/preferences.service';
import { RequestService } from '@proman/services/request.service';
import { PromanTranslationService } from '@proman/services/proman-translation.service';

@Injectable({ providedIn: 'root' })
export class FilterService {
    systemOptions: SystemOptions;
    currUser: CurrUser;
    currLang: string;

    constructor(
        private ACL: ACL,
        private PromanTranslation: PromanTranslationService,
        private store: Store,
        private DateTime: DateTimeFormatService,
        private Currencies: CurrenciesService,
        private AppInit: AppInitService,
        private Prefs: PreferencesService,
        private Request: RequestService,
    ) {
        this.store.select(getSystemOptions)
            .subscribe((value) => this.systemOptions = value);
        this.store.select(getCurrUser)
            .subscribe((value) => this.currUser = value);

        this.currLang = this.Prefs.language();
    }

    quantQuantity(quant: MaterialQuant, material?: Material) {
        const _material = quant && quant.material || material;

        if (!quant || !_material) {
            return '';

        }

        return (+quant.quantity).toFixed(0) + '' + (_material && _material.materialType && _material.materialType.unit || '');
    }

    quantFormat(quant: MaterialQuant) {

        if (!quant) return '';

        return resourcesConfig.material_quant.utils.getFormat(quant);

    }

    parseQuantsNames(quants: MaterialQuant[], material?: Material) {
        return quants?.map((quant) => {
            quant.name = this.quantName(quant, material);
            quant._descr = quant.production ? (this.date(quant.production.createdAt) + ' ' + quant.production.name) : '';

            return quant;
        });
    }

    quantName(quant: MaterialQuant, material?: Material) {
        return this.quantFormat(quant) + ' ' + this.quantQuantity(quant, material) + ' ' + quant.type;
    }

    date(value: string|Moment, _format: string = '_date_js'): string {
        const format = this.DateTime.get(_format);

        if (!isValidDateString(value)) return '';

        return value && moment(value).format(format) || value as string;
    }

    dateTime(value: any) {
        const format = this.DateTime.get('_datetime_js');

        if (!isValidDateString(value)) return '';

        return value && moment(value).format(format) || value;
    }

    translate = (value: any, params?: any) => {
        return this.PromanTranslation.translate(value, params);
    };

    timeRange(start: any, end: any) {
        return (start && end) ? (moment(start).format('HH:mm') + ' - ' + moment(end).format('HH:mm')) : null;
    }

    duration(durationInSeconds: number, units: any = { d: 'auto', h: 'auto', m: true }, options: any = { nonZero: true }) {
        const durationValue = durationFromSeconds(durationInSeconds);
        const segments = [];
        const meta = {
            d: 'day_shorthand',
            h: 'hour_shorthand',
            m: 'minute_shorthand',
            s: 'second_shorthand'
        };

        if (durationInSeconds < 60) units = { d: 'auto', h: 'auto', m: 'auto', s: true };

        for (const unit in meta) {

            if (units[unit]) {
                if ((options && options.nonZero && durationValue[unit] === 0) ||
                    (units[unit] === 'auto' && !durationValue[unit])) {
                    continue;
                }

                segments.push((durationValue[unit] || 0) + ' ' + this.translate(meta[unit]));
            }
        }

        return segments.join(' ');
    }

    currencyShorthand(name: string) {
        return this.Currencies.dataMappedByName[name].shorthand;
    }

    decimal(value: any, digits?: number, unit?: any) {
        let output: string;

        output = isNaN(value) ? '0' : value;

        if (typeof output === 'string' || typeof output === 'number') {
            output = parseFloat(output).toFixed(digits);
        }

        if (unit) output += ` ${unit}`;

        return output;
    }

    acl(value: any) {
        if (!isArray(value)) return value;

        const l = value.length;
        const out = [];

        for (let i = 0; i < l; i++) {
            const state = value[i];
            if (!state.acl || this.ACL.check(state.acl)) out.push(state);
        }

        return out;
    }

    smartDateRange = (start: string|Moment, end: string|Moment) => {

        const format = this.DateTime.get('_short_datetime_js');
        let endFormat;

        if (!start && !end) return '';
        if (!start || !end) {
            return start ? moment(start).format(format) + ' - ' : '' + end ? moment(end).format(format) : '';
        }

        start = moment(start);
        end = moment(end);

        endFormat = format;

        if (start.year() === end.year()) {
            endFormat = endFormat.replace('YY-', '');

            if (start.month() === end.month()) {
                endFormat = endFormat.replace('MM-', '');

                if (start.day() === end.day()) {
                    endFormat = endFormat.replace('DD ', '');
                }
            }
        }

        return start.format(format) + ' - ' + end.format(endFormat);
    };

    uppercase(value: string) {
        return uppercase(value);
    }

    contrastingColor(value: string) {
        const color: string = (value || 'FFFFFF');

        // Source: http://stackoverflow.com/a/6511606

        function hexToRGBArray(color: any) {
            const rgb = [];

            if (color.length === 3) {
                color = color.charAt(0) + color.charAt(0) + color.charAt(1) + color.charAt(1) + color.charAt(2) + color.charAt(2);
            } else if (color.length !== 6) {
                color = 'ffffff';
            }

            for (let iter = 0; iter < 3; iter++) {
                rgb[iter] = parseInt(color.substr(iter * 2, 2), 16);
            }

            return rgb;
        }

        function luma(color: any) {
            const rgb = (typeof color === 'string') ? hexToRGBArray(color) : color;

            return (0.2126 * rgb[0]) + (0.7152 * rgb[1]) + (0.0722 * rgb[2]);
        }

        function getContrastingColor(color: any) {
            return (luma(color) >= 165) ? '000' : 'fff';
        }

        return `#${getContrastingColor(color)}`;
    }

    price(value: Money | number, decimal: number = 2) {
        const baseCurrency = this.currUser.defaultCurrency || this.Currencies.base;

        let output = '';
        const mappedByName = this.Currencies.dataMappedByName;

        if (value && typeof value === 'object' && value.currency && mappedByName[value.currency]) {
            output = `${parseFloat(value.amount).toFixed(decimal)} ${mappedByName[value.currency].shorthand}`;

        } else if (value && (typeof value === 'string' || typeof value === 'number')) {
            const foo: any = value;
            output = `${parseFloat(foo).toFixed(decimal)} ${baseCurrency.shorthand}`;

        } else if (value && typeof value === 'object' && !mappedByName[value.currency]) {
            console.warn(`No currency found: '${value.currency}'. Available currencies: ${Object.keys(mappedByName).join(', ')}`);
            output = `${parseFloat(value.amount).toFixed(decimal)}`;
        }

        return numberDecimalComma(output);
    }

    maxLength(value: string, maxLength: number, excludeDots: boolean = false) {
        return maxLength && value && value.length > maxLength ? `${value.substr(0, maxLength)}${excludeDots ? '' : '...'}` : value;
    }

    interpolateString(main: string, params: any) {

        if ((params && typeof params === 'object') && Object.keys(params).length) {
            for (const key in params) {
                main = main.replace(`{{ ${key} }}`, params[key]);
            }
        }

        return main;
    }

    time(value: string) {
        const parts = value.split(':');

        return `${parts[0]}:${parts[1]}`;
    }

    utcTime(value: string) {
        return this.time(utcTime(value));
    }

    getSystemOptionsDayStartTime(time: string): string {
        const timeStart: string[] = this.systemOptions.workingDayStartsAt ? this.systemOptions.workingDayStartsAt.split(':') : ['00', '00'];

        const setTimeStart = (timeObj: Moment): Moment => {
            return timeObj.hour(+timeStart[0]).minutes(+timeStart[1]);
        };

        return setTimeStart(moment(time)).format();
    }

    getSystemOptionsDayEndTime(time: string): string {
        const timeStart: string[] = this.systemOptions && this.systemOptions.workingDayStartsAt ? this.systemOptions?.workingDayStartsAt.split(':') : ['00', '00'];
        const timeEnd: string[] = this.systemOptions && this.systemOptions.workingDayFinishAt ? this.systemOptions?.workingDayFinishAt.split(':') : ['23', '59'];

        const isEndNextDay = timeStart.join() > timeEnd.join();

        const setTimeEnd = (timeObj: Moment): Moment => {
            return timeObj.hour(+timeEnd[0]).minutes(+timeEnd[1]).add(isEndNextDay ? 1 : 0, 'd');
        };

        return setTimeEnd(moment(time)).format();
    }

    getWorkdayTimeLimitStatus(): boolean {
      return this.systemOptions.useWorkdayTimeLimits;
    }

}
