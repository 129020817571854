import { Injectable } from '@angular/core';
import { LocalStorageService } from '@proman/services/local-storage.service';
import { isDefined } from '@proman/utils';
import moment from 'moment';

const LOGGER_KEY = 'LOGGER';
declare interface LoggerServiceTimeMap {
    [key: string]: {
        default: number;
        warn: number|null;
        error: number|null;
    }
}

@Injectable({ providedIn: 'root' })
export class LoggerService {
    isLogger: boolean;
    timeMap: LoggerServiceTimeMap = {};

    constructor(
        private LocalStorage: LocalStorageService,
    ) {
        this.isLogger = isDefined(this.LocalStorage.get(LOGGER_KEY))
    }

    log(...data: any[]) {
        if (this.isLogger) console.log('LOGGER', ...data);
    }

    track(key: string, warnTime: number|null = null, errorTime: number|null = null) {
        this.timeMap[key] = {
            'default': Date.now(),
            warn: warnTime,
            error: errorTime,
        };
    }

    trackComplete(key: string) {
        const time = moment.duration(Date.now() - this.timeMap[key]['default']).asSeconds();
        const errorTime = this.timeMap[key]['error'];
        const warnTime = this.timeMap[key]['warn'];
        this.log(`action '${key}' complete in ${time}s`);
        if (errorTime && time > errorTime) {
            console.log('should send error log request');
        } else if (warnTime && time > warnTime) {
            console.log('should send warning log request');
        }
        delete this.timeMap[key];
    }

}
