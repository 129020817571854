import { pairwise, filter } from '@proman/rxjs-common';
import { Injectable } from '@angular/core';
import { menus } from '../../settings/menu/menus';
import { ACL } from '@proman/services/acl.service';
import { NavigationEnd, Router } from '@angular/router';
import { routeMap } from '../proman-route-map';
import { Location } from '@angular/common';
import { cloneDeep, EMPTY_VALUE, isSmarton } from "@proman/utils";
import { PublicSystemOptions, Specialisation, SystemOptions } from '@proman/interfaces/entity-interfaces';
import { Store } from '@ngrx/store';
import { getPublicSystemOptions, getSystemOptions } from '@proman/store/system-options';
import { getCurrUser } from '@proman/store/curr-user';
import { CurrUser, PromanMenuType } from '@proman/interfaces/object-interfaces';

@Injectable({ providedIn: 'root' })
export class MenuService {
    items: any = [];
    states: any = {};
    activeCategory: any;
    activeCategoryTab: any;
    systemOptions: SystemOptions;
    publicSystemOptions: PublicSystemOptions;
    currUser: CurrUser;

    constructor(
        private ACL: ACL,
        private store: Store,
        private Router: Router,
        private Location: Location,
    ) {
        this.Router.events.pipe(
            filter((event) => (event instanceof NavigationEnd )),
            pairwise())
            .subscribe((events: any[]) => {
                this.updateActiveElements(events[1].urlAfterRedirects);
            });

        this.store.select(getSystemOptions)
            .subscribe((value) => this.systemOptions = value);

        this.store.select(getPublicSystemOptions).subscribe((value) => this.publicSystemOptions = value);

        this.store.select(getCurrUser)
            .subscribe((value) => this.currUser = value);
    }

    isActive(item: any) {

        if (item.tabs) {

            for (const tab of item.tabs) {

                if (tab.state === this.activeCategoryTab) {
                    return true;
                }

            }

        }

        return false;
    }

    init() {
        this.items = this.get();
    }

    updateActiveElements = (url: string = this.Location.path()) => {
        let temp: any;

        for (const state in routeMap) {
            const item = routeMap[state];

            if (!temp && url.startsWith(`/${item}`)) {
                this.activeCategoryTab = state;
                temp = true;
            }

            if (temp && url === `/${item}`) { // if one state partially matches, check for other full match

                 if (this.items.some((item: any) => item.tabs && item.tabs.some((tab: any) => tab.state === state))) { // if url equals to state from menu list
                    this.activeCategoryTab = state;
                    break;
                 }
            }

        }

        for (const category of this.items) {

            if (this.isActive(category)) {
                this.activeCategory = category;

                if (category.tabs) {

                    for (const categoryTab of category.tabs) {

                        if (this.isActive(categoryTab)) {
                            this.activeCategoryTab = categoryTab;

                            break;

                        }

                    }

                }

                break;

            }

        }

    };

    get() {
        const currUser = this.currUser;
        const corporateFilterItem = (item: any) => {
            if (this.publicSystemOptions.corporateChild) {
                return !item.corporateRestrict;
            } else {
                return true;
            }
        }

        const adminFilterItem = (item: any) => {
            if (item.adminRestrict) {
                return currUser.person.isAdmin;
            }
            return true;
        }

        const isMenuItemEnabled = (item: any) => {
            let isEnabled = true;

            if (this.publicSystemOptions.corporateChild) isEnabled = !item.corporateRestrict;
            const filterItem = (item: any) => {
                return item.chef ? isSmarton() :
                        item.acl ? this.ACL.check(item.acl)
                            : true;
            };

            if (item.tabs && isEnabled) {
                item.tabs = item.tabs.filter(filterItem);
                item.tabs = item.tabs.filter(corporateFilterItem);
                item.tabs = item.tabs.filter(adminFilterItem);

                isEnabled = !!item.tabs.length;

            } else if (item.state) {
                isEnabled = filterItem(item);
                if (type === 'customer' && item.showKey) isEnabled = currUser.person.customerSettings[item.showKey];
            } else if (!item.state) {
                isEnabled = false;

            }

            return isEnabled;
        };

        if (!this.systemOptions) return;

        const menuSettings = this.systemOptions.defaultEmployeeMenuSettings;

        const data = Object.assign({}, currUser.bookkeepingUser ? {} : menuSettings);
        const employee = currUser.person;

        for (const key in currUser.person.menuSettings) {
            if (currUser.person.menuSettings[key] === EMPTY_VALUE) {
                delete currUser.person.menuSettings[key];
            }
        }

        if (employee) {
            employee.specialisations
                .forEach((spec: Specialisation) => {
                    Object.assign(data, spec.menuSettings || {});
                });

        }

        Object.assign(data, currUser.person.menuSettings || {});

        for (const key in data) {
            data[key] = data[key] === 'true';
        }


        const isMenuItemEnabledNew = (item: any) => {
            let isEnabled = true;

            if (this.publicSystemOptions.corporateChild) isEnabled = !item.corporateRestrict;

            if (item.tabs && isEnabled) {
                item.tabs = item.tabs
                    .filter((tab: any) => data[`${item.name}.${tab.name}`]);
                item.tabs = item.tabs.filter(corporateFilterItem);
                item.tabs = item.tabs.filter(adminFilterItem);

                isEnabled = !!item.tabs.length;

            } else if (item.state) {
                isEnabled = data[item.name];
                if (type === 'customer' && item.showKey) isEnabled = currUser.person.customerSettings[item.showKey];

            } else if (!item.state) {
                isEnabled = false;

            }

            return isEnabled;
        };
        const type = currUser.type as string;
        const newMenusEnabled = !!currUser.person.menuLimitations;

        // return cloneDeep(menus[type]).filter(isMenuItemEnabled);
        return cloneDeep(menus[type])
            .filter(newMenusEnabled ? isMenuItemEnabledNew : isMenuItemEnabled);
    }

    getFullMenu(type: string = this.currUser.type):PromanMenuType {
        return cloneDeep(menus[type]);
    }

    destroy() {
        this.items = [];
    }

}
