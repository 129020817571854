export const menuEmployee = [
    {
        type: 'group',
        name: 'orders',
        icon: 'tasks',
        tabs: [
            {
                name: 'orders',
                state: 'Orders',
                acl: 'order.display',
            },
            {
                name: 'production',
                state: 'Productions',
                acl: 'production.display',
            },
            {
                name: 'shipments',
                state: 'Shipments',
                acl: 'shipment.display',
            },
            {
                name: 'jobs',
                state: 'EmployeesJobsList',
                acl: 'event.display',
            },
            {
                name: 'calendar',
                state: 'Calendar',
                acl: 'calendar.view',
            },
            {
                name: 'boards',
                state: 'Boards',
                acl: 'boards.view',
            },
            {
                name: 'orders_products',
                state: 'OrdersProducts',
                acl: 'order.display',
            },
            {
                name: 'stored_products',
                state: 'Products',
                acl: 'product.display',
            },
            {
                name: 'product_containers',
                state: 'ProductContainers',
                acl: 'product.edit',
            },
            {
                name: 'operations',
                state: 'Events',
                acl: 'event.display',
            }
        ]
    },
    {
        type: 'group',
        name: 'sales',
        icon: 'briefcase',
        tabs: [
            {
                name: 'projects',
                state: 'SaleOpportunities',
                acl: 'sale_opportunity.display',
            },
            {
                name: 'order_proposals',
                state: 'OrderProposals',
                acl: 'order.display',
            },
            {
                name: 'products_exposure',
                state: 'ArticlesExposure',
                acl: 'article.display',
            },
            {
                name: 'consumers',
                state: 'Consumers',
                acl: 'consumer.view',
            },
            {
                name: 'pos',
                state: 'Pos',
                acl: 'consumer.view',
            },
            {
                name: 'customers',
                state: 'Customers',
                acl: 'customer.display',
            },
            {
                name: 'agents',
                state: 'Agents',
                acl: 'agent.view',
            },
            {
                name: 'sales_events',
                state: 'SaleEvents',
                acl: 'customer.display',
            },
            {
                name: 'presentations',
                state: 'Documentation',
                acl: 'file.display',
            },
        ]
    },
    {
        type: 'group',
        name: 'technology',
        icon: 'certificate',
        tabs: [
            {
                name: 'articles',
                state: 'Articles',
                acl: 'article.display',
            },
            {
                name: 'article_categories',
                state: 'ArticleCategories',
                acl: 'article.display',
            },
            {
                name: 'parameters',
                state: 'Parameters',
                acl: 'parameter.display',
            },
            {
                name: 'functions',
                state: 'Expressions',
                acl: 'parameter.display',
            },
            {
                name: 'features',
                state: 'Features',
                acl: 'parameter.display',
            },
            {
                name: 'development',
                state: 'DevelopmentProjects',
                acl: 'development_project.display',
            },
            {
                name: 'tests',
                state: 'ArticlesTests',
                acl: 'article.edit'
            },
            {
                name: 'comments',
                state: 'ArticleHistory',
                acl: 'article.edit',
            }
        ]
    },
    {
        type: 'group',
        name: 'resources',
        icon: 'building',
        tabs: [
            {
                name: 'resources',
                state: 'ResourcesList',
                acl: 'workplace.display',
            },
            {
                name: 'time_options',
                state: 'TimeOptions',
                acl: 'time_options.display',
            },
            {
                name: 'premises',
                state: 'WorkplacesInfo',
                acl: 'workplace.edit',
            },
            {
                name: 'maintenance',
                state: 'MaintenanceList',
                acl: 'maintenance.display',
            },
            {
                name: 'subcontractors',
                state: 'Subcontractors',
                acl: 'subcontractor.display',
            },
            {
                name: 'cameras',
                state: 'Cameras',
                acl: 'workplace.edit',
            },
        ]
    },
    {
        type: 'group',
        name: 'raw_materials',
        icon: 'database',
        tabs: [
            {
                name: 'materials',
                state: 'Materials',
                acl: 'material.display',
            },
            {
                name: 'material_quants',
                state: 'MaterialQuants',
                acl: 'material.display'
            },
            {
                name: 'material_categories',
                state: 'MaterialCategories',
                acl: 'material_category.display',
            },
            {
                name: 'material_types',
                state: 'MaterialTypes',
                acl: 'material.display',
            },
            {
                name: 'dimensions',
                state: 'Dimensions',
                acl: 'material.edit',
            },
            {
                name: 'materials_formulas',
                state: 'MaterialsFormulas',
                acl: 'formula.display',
            },
            {
                name: 'purchases',
                state: 'Purchase',
                acl: 'purchase.display',
            },
            {
                name: 'materials_movement',
                state: 'MaterialRemnantsMovementList',
                acl: 'material.display',
            },
            {
                name: 'materials_reservations',
                state: 'MaterialsReservations',
                acl: 'material.edit'
            },
            {
                name: 'tests',
                state: 'MaterialsTests',
                acl: 'material.display',
            }
        ]
    },
    {
        type: 'group',
        name: 'inventory',
        icon: 'industry',
        tabs: [
            {
                name: 'inventory',
                state: 'Inventors',
                acl: 'material.display',
            },
            {
                name: 'categories',
                state: 'InventoryCategories',
                acl: 'material_category.display',
            },
            {
                name: 'types',
                state: 'InventoryTypes',
                acl: 'material.display',
            },
            {
                name: 'purchases',
                state: 'InventoryPurchase',
                acl: 'material.display',
            },
            {
                name: 'tests',
                state: 'InventoryTests',
                acl: 'material.display'
            },
            {
                name: 'inventory_tags',
                state: 'InventoryTags',
                acl: 'material.display',
            },
        ]
    },
    {
        type: 'group',
        name: 'staff',
        icon: 'users',
        tabs: [
            {
                name: 'employees',
                state: 'Employees',
                acl: 'employee.display',
            },
            {
                name: 'roles',
                state: 'Roles',
                acl: 'role.display',
                corporateRestrict: true,
            },
            {
                name: 'tabel',
                state: 'Tabel',
            },
            {
                name: 'notifications',
                state: 'Actions',
                acl: 'employee.view_all',
            },
            {
                name: 'subordination',
                state: 'SubordinationTree',
                acl: 'employee.display',
                corporateRestrict: true,
            },
            {
                name: 'permissions',
                state: 'PermissionsMap',
                acl: 'permission.edit',
                corporateRestrict: true,
            },
            {
                name: 'documents',
                state: 'EmployeesDocuments',
                acl: 'employee.display',
            },
            {
              name: 'trips',
              state: 'Trips',
              acl: null,
            },
            {
              name: 'files',
              state: 'Files',
              acl: null,
            },
        ]
    },
    {
        type: 'group',
        name: 'reports',
        icon: 'chart-area',
        tabs: [
            {
                name: 'sales',
                state: 'SalesReports',
                acl: 'reports.master',
            },
            {
                name: 'production',
                state: 'ProductionReports',
                acl: 'reports.master',
            },
            {
                name: 'loading_reports',
                state: 'LoadingReports',
                acl: 'reports.master',
            },
            {
                name: 'materials',
                state: 'MaterialsReports',
                acl: 'reports.master',
            },
            {
                name: 'staff',
                state: 'EntranceReport',
                acl: 'reports.master',
            }
        ]
    },
    {
        type: 'group',
        name: 'tables',
        icon: 'table',
        tabs: [
            {
                name: 'tables',
                state: 'DynamicTables',
                acl: 'dynamic.view',
            },
            {
                name: 'personal_tables',
                state: 'PersonalDynamicTables',
                acl: 'dynamic.display',
            },
            {
                name: 'charts',
                state: 'DynamicTablesCharts',
                acl: 'dynamic.view',
            },
            {
                name: 'scoreboards',
                state: 'DynamicTablesAggregatedTables',
                acl: 'dynamic.view',
            },
            {
                name: 'dynamic_queries',
                state: 'DynamicQueries',
                acl: 'dynamic.view'
            }
        ]
    },
    {
        type: 'group',
        name: 'accounting',
        icon: 'calculator',
        tabs: [
            {
                name: 'invoices',
                state: 'Invoices',
                acl: 'invoice.display',
            },
            {
                name: 'balance',
                state: 'Balance',
                acl: 'invoice.edit',
            },
            {
                name: 'reports',
                state: 'Reports',
                acl: 'invoice.view',
            },
            {
                name: 'payments',
                state: 'AccountingPayments',
                acl: 'invoice.view',
            },
            {
                name: 'suppliers',
                state: 'Suppliers',
                acl: 'supplier.display',
            },
            {
                name: 'services',
                state: 'Services',
                acl: 'invoice.edit',
            },
            {
                name: 'carriers',
                state: 'Carriers',
                acl: 'carrier.display',
            },
            {
                name: 'assets',
                state: 'AccountingAssets',
                acl: 'invoice.edit',
            },
            {
                name: 'taxes',
                state: 'Taxes',
                acl: 'invoice.master',
            },
            {
                name: 'purchases',
                state: 'Purchase',
                acl: 'purchase.display',
            },
            {
                name: 'inventorization',
                state: 'Inventorization',
                acl: 'material.edit',
            },
            {
                name: 'accounting_props',
                state: 'AccountProps',
                acl: 'invoice.edit',
            },
        ]
    },
    {
        type: 'group',
        name: 'settings',
        icon: 'cogs',
        tabs: [
            {
                name: 'system',
                state: 'SystemSettingsCommon',
                acl: 'system_options.edit',
            },
            {
                name: 'templates',
                state: 'SystemSettingsTemplates',
                acl: 'templates.view',
            },
            {
                name: 'dynamic_fields',
                state: 'DynamicFields',
                acl: 'dynamic.view',
            },
            {
                name: 'remote_services',
                state: 'SystemSettingsDevices',
                acl: 'system_options.edit',
            },
            {
                name: 'countries',
                state: 'CrmCountries',
                acl: 'role.display',
            },
            {
                name: 'logs',
                state: 'SystemSettingsEventsLog',
                acl: 'system_options.edit',
            },
            {
                name: 'time_restrictions',
                state: 'SystemSettingsTimeTags',
                acl: 'tag.edit'
            },
            {
                name: 'discounts',
                state: 'SystemSettingsDiscounts',
                acl: 'tag.edit'
            },
            {
                name: 'menu',
                state: 'SystemSettingsMenu',
                acl: 'system_options.edit',
            },
        ]
    }
];
